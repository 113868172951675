.content {
  background: white;
  padding: 10px;
  width: 75%;
  height: 100%;
  display: none;
}

.active-content {
  display: block;
  padding: 10px;
}
