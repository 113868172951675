.header {
  color: white;
  width: 100%;
  position: relative;
  background-color: black;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-items {
  list-style: none;
  display: flex;
}

.nav-logo {
  width: 500px;
}

.nav-items > li {
  padding: 10px;
}

.tabs {
  text-align: center;
  cursor: pointer;
}

.active-tabs  {
  background: grey;
  padding: 10px;
  border: 1px solid white;
}
