.contactUs-form {
  display: block;
  padding:4px 4px;
  border:solid 2px #aacfe4;
  width:75%;
}

input[type=text] {
  display: block;
  margin: 5px 0;
  width: 100%;
}

input[type=email] {
  display: block;
  margin: 5px 0;
  width: 100%;
}

.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}

.active-content {
  display: block;
}

.message
{
  height:100px;
}
