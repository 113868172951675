.accordion {
  width: 25%;
  height: 100%;
  padding: 10px;
  background: burlywood;
  float:right;
}

.faq {
  background: #f0ebe1;
  margin-bottom: 5px;
  padding: 10px 10px;
}

.question {
  color: #85662b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.answer {
  color: #8b7f75;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}

.answer.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1,0,1,0);
}
