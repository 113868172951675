.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-items {
  list-style: none;
  display: flex;
}

.nav-logo {
  width: 20%;
}

.nav-items > li {
  padding: 10px;
}

.content {
  background: white;
  padding: 10px;
  width: 75%;
  height: 100%;
  display: none;
}

.active-content {
  display: block;
  padding: 10px;
}
