.footer {
  position: fixed;
  bottom: 0;
  height: 5%;
  width: 100%;
  display: flex;
  color: white;
  background-color: black;
}



